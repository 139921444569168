<template>
    <setting-layout :title="$t('dialogs.top_up')">
        <v-container>
            <!-- tabs -->
            <v-row align="center" justify="center" class="tabs mb-4">
                <v-col cols="6">
                    <v-btn
                        elevation="2"
                        x-large
                        class="tabs__tab"
                        :class="{ 'tabs__tab--active': tab === 'card' }"
                        @click="tab = 'card'"
                    >
                        Card
                    </v-btn>
                </v-col>

                <v-col cols="6">
                    <v-btn
                        elevation="2"
                        x-large
                        class="tabs__tab"
                        :class="{ 'tabs__tab--active': tab === 'crypto' }"
                        @click="tab = 'crypto'"
                    >
                        Crypto
                    </v-btn>
                </v-col>
            </v-row>

            <v-row justify="center" align="center">
                <span class="dollar">{{ currency }}</span>
                <span class="balance"> {{ count }}.00 </span>
            </v-row>
            <v-row>
                <v-col
                    cols="3"
                    class="text-center badge"
                    v-for="(item, i) in [10, 25, 50, 100]"
                    :key="i"
                >
                    <!-- TODO: fix badge-->
                    <v-chip large outlined @click="count = item"
                        >{{ currency }}{{ item }}
                    </v-chip>
                </v-col>
            </v-row>
            <!--            <v-row>
                <v-col>
                    From
                </v-col>
            </v-row>
            <router-link :to="{name: 'user.own.profile.wallet.addCard'}">
                <v-row align="center" class="select-card ma-0">
                    <v-col cols="2">
                        <app-icon icon="bank" color="rgba(255,255,255,0.4)"/>
                    </v-col>
                    <v-col cols="8">
                        <v-row>
                            <v-col class="pa-1 text-h6">Credit Card</v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pa-1 text-h6">*** *** *** 123</v-col>
                        </v-row>
                    </v-col>
                    <v-col>
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-col>
                </v-row>
            </router-link>-->

            <v-row
                justify="center"
                class="mt-6 mr-5 ml-5 text-grey-500 text-caption"
            >
                <div style="opacity: 0.5">
                    {{ currency }}{{ count + (vatRate * count) / 100 }} (VAT
                    included) + {{ currency }}{{ 1 }} processing fee
                </div>
            </v-row>

            <v-row justify="center" class="mt-4 mr-5 ml-5">
                <gradient-button
                    block
                    :loading="
                        $store.getters.loadingBtn(['setting', 'topUpBtn'])
                    "
                    @click="submit"
                >
                    {{
                        tab === "card"
                            ? $t("dialogs.top_up_via_card")
                            : $t("dialogs.top_up_via_crypto")
                    }}
                </gradient-button>
            </v-row>
        </v-container>
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import GradientButton from "@/components/app/button/GradientButton";
import { CURRENCY_SYMBOL } from "@/configs/constants";
import { mapActions, mapState } from "vuex";

export default {
    name: "TopUp",
    data: () => ({
        count: 100,
        currency: CURRENCY_SYMBOL,
        vatRate: 0,
        tab: "card",
    }),
    computed: {
        ...mapState({
            user: (state) => state.user.user.data,
        }),
    },
    methods: {
        ...mapActions(["sendTopUpViaCard", "sendTopUpViaCrypto"]),
        submit() {
            if (this.tab === "card") {
                this.sendTopUpViaCard({ model: this.count }).then((value) => {
                    window.location.href = value.redirectUrl;
                });
            }

            if (this.tab === "crypto") {
                this.sendTopUpViaCrypto({ model: this.count }).then((value) => {
                    window.location.href = value.redirectUrl;
                });
            }
        },
    },
    components: { GradientButton, SettingLayout },
    mounted() {
        axios.get("/api/wallet/vat-rate", { hideLoader: true }).then((response) => {
            this.vatRate = response.data.vatRate;
        });

        this.$mixpanel.identifyUser(this.user.email);
        this.$mixpanel.pageViewed('Topup');
    },
};
</script>

<style scoped lang="scss">
@import "@/sass/modules/_variables";

.balance {
    font-size: 4.3em;
}

.dollar {
    font-size: 1.7em;
    color: rgba(255, 255, 255, 0.3);
}

.select-card {
    background: rgba(237, 237, 237, 0.1);
    border-radius: 6px;
}

.tabs {
    .tabs__tab {
        background: rgba(237, 237, 237, 0.1) !important;
        color: rgba(255, 255, 255, 0.5);
        width: 100%;
        height: 48px;
        transition: 0.3s;

        &.tabs__tab--active {
            background: $accent-background !important;
            color: white;
        }
    }
}
</style>
